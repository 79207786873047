<script>
import Banner from "@/views/components/Banner.vue";
import notData from "@/views/components/Not_data.vue";
import LeftMenu from "@/views/components/LeftMenu.vue";
import Breadcrumb from "@/views/components/Breadcrumb.vue";
import { tabsOption } from "@/views/hotelClub/moke";
import LearnMore from "@/views/components/LearnMore.vue";

export default {
  name: "index",
  components: { LearnMore, notData, LeftMenu, Breadcrumb, Banner },
  data() {
    return {
      tabsOption: tabsOption,
      currentTab: {}, // 当前选中的标签页数据
      breadcrumbOption: [], // 面包屑
      childSelect: {}, // 页面选中的节点
      showData: {} // 要显示得数据对象
    };
  },
  mounted() {
    this.currentTab = this.tabsOption[0];
    this.childSelect = this.tabsOption[0];
    this.checkTab(this.currentTab.key);
  },
  methods: {
    /**
     * 切换一级目录
     * @param key
     */
    checkTab(key) {
      this.childSelect = {};
      this.showData = {};
      // 初始化三级目录
      const tabsOption = this.tabsOption;
      tabsOption.map(item => {
        if (item.key === key) {
          this.currentTab = item;

          // 添加面包屑
          const currentOption = [
            {
              key: "hotelClub",
              toPath: "/hotelClub"
            }
          ];
          currentOption.push({
            key: item.key,
            title: item.title,
            toPath: "/hotelClub"
          });
          this.breadcrumbOption = currentOption;
          this.checkChildSelect(item, 2);
        }
      });
    },
    /**
     * 切换二级，三级目录保存数据
     * @param item
     * @param menuCount 面包屑的位数
     */
    checkChildSelect(item, menuCount) {
      // 添加面包屑 （暂时关闭）start
      const currentOption = this.breadcrumbOption;
      const isFlog = currentOption.some(o => o.key === item.key);
      if (!isFlog) {
        const newOption = {
          key: item.key,
          title: item.title,
          toPath: "/artist?sel=" + item.key
        };
        currentOption.splice(menuCount, menuCount + 1, newOption);
        this.breadcrumbOption = currentOption;
      }
      // 添加面包屑 end
      // 没有三级目录，三级目录不需要选中当前的菜单节点
      if (item.menuChild && item.menuChild.length === 0) {
        this.childSelect = item.menuChild[0]; // 设置当前选中的内容
        this.showData = item;
      } else {
        // 清除数据
        this.childSelect = item;
        this.showData = item;
      }
    }
  }
};
</script>

<template>
  <div>
    <Banner
      :bannerSrc="require('@/assets/banner/hoteClub.jpg')"
      :tabs="tabsOption"
      :selTab="currentTab.key"
    />
    <div class="padding-comm-warp _content_warp hote_club_warp">
      <div class="left">
        <LeftMenu :currentTab="currentTab" :childSelect="childSelect" />
      </div>
      <div class="right">
        <Breadcrumb :breadcrumbList="breadcrumbOption" />
        <div
          class="content"
          v-if="
            showData.key === 'gallery' &&
              showData.itemList &&
              showData.itemList.length !== 0
          "
        >
          <div
            v-for="item in showData.itemList"
            class="gallery_item_warp"
            :key="item.key"
          >
            <el-image :src="item.img" lazy />
            <div class="gallery_item_desc" v-html="item.title"></div>
          </div>
        </div>
        <div
          class="content"
          v-else-if="
            showData.itemData && Object.keys(showData.itemData).length !== 0
          "
        >
          <el-image :src="showData.itemData.img" lazy />
          <div class="desc" v-html="showData.itemData.desc"></div>
        </div>
        <div v-else>
          <notData />
        </div>
        <div v-if="showData.itemList && showData.itemList.length !== 0">
          <LearnMore />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../index.scss";
.hote_club_warp {
  display: flex;
}
.hote_club_warp .right .content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .gallery_item_warp {
    width: 30%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 60px;
    .el-image {
      width: 100%;
    }
    .gallery_item_desc {
      padding: 20px 0 40px 20px;
      color: $theme-red;
      font-size: 1.2rem;
    }
  }
}
.hote_club_warp .right .content .desc {
  margin: 20px 0 0;
  line-height: 30px;
}
</style>
